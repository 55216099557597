/* Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

/* General */
html,
body {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  font-family: 'Open Sans', sans-serif;
}

/* Links */
a {
  color: #ec6c5d;
}

a:hover {
  color: #ec6c5d;
  text-decoration: none;
}

/* Buttons */
.btn-primary {
  background-color: #3a4e48;
  border-color: #3a4e48;
}

.btn-primary:hover {
  background-color: #3a4e48;
  border-color: #3a4e48;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #3a4e48;
  border-color: #3a4e48;
  opacity: 0.5;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: #3a4e48;
  border-color: #3a4e48;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #3a4e48;
  border-color: #3a4e48;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

/*  Custom buttons */

.button-orange {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
}

.button-orange:hover {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.button-orange.btn-primary.disabled,
.button-orange.btn-primary:disabled {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
  opacity: 0.5;
}

/* Login Page */
.logo {
  max-width: 50%;
  margin-top: 4em;
  margin-bottom: 4em;
}

.reset-password {
  float: right;
}

/* Dashboard */

.dashboard-title {
  margin: 1rem 0;
}

.dashboard-image {
  width: 30%;
}

.welcome-row {
  margin-bottom: 100px;
  margin-top: 50px;
}

.dashboard-header {
  text-align: center;
  font-size: x-large;
  font-weight: 600;
}

.dashboard-custom-button-container {
  align-items: center;
  text-align: center;
}

.dashboard-list {
  max-width: fit-content;
  margin-bottom: 2rem;
}

.dashboard-list .list-group-item {
  padding: 1rem;
  color: #3a4e48;
}

/* Main Wrapper */
.main-wrapper {
  background-color: #f3f2ef;
}

/* Header */
.header {
  background-color: #ffffff;
  padding: 1em;
  box-shadow:
    0 1.6px 3.6px rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px rgba(0, 0, 0, 0.108);
}

.sign-out {
  text-decoration: none;
  color: #ec6c5d;
}

/* Main content */
.main-content {
  background-color: #f3f2ef;
  margin-top: 2em;
  padding: 1em;
}

@media (max-width: 768px) {
  .main-content {
    padding: 0em;
    margin-top: 0em;
  }
}

/* Sidebar */

/* User wrapper */
.user-wrapper {
  display: flex;
  flex-direction: column;
}

.user-wrapper p:first-child {
  margin-bottom: 0;
}

/* Menu */
.navbar-expand .navbar-nav .nav-link {
  padding-left: 0;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: flex-end;
    padding-right: 12px;
  }
}

.menu-wrapper .navbar-nav .nav-link,
.mobile-menu .nav-link {
  color: #3a4e48;
}

.mobile-menu .dropdown-menu {
  border: none;
  padding: 0.25rem 0;
  margin-left: 1em;
}

/* Dropdown */
.navbar-dropdown .dropdown-menu {
  background-color: transparent;
  border: none;
  padding: 0 0 0 1em;
}

.dropdown-item {
  color: #3a4e48;
  padding: 0.25rem 0 0.25rem 0;
}

.dropdown-item:hover {
  color: #3a4e48;
}

.navbar-dropdown .dropdown-toggle.btn-primary {
  background-color: transparent;
  border: none;
  filter: none;
  color: #3a4e48;
  padding: 0.5rem 0 0.5rem 0;
  transition: none;
}

.navbar-dropdown .dropdown-item.active,
.navbar-dropdown .dropdown-item:active {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dropdown .dropdown-item:focus,
.navbar-dropdown .dropdown-item:hover {
  background-color: transparent;
}

.content-wrapper {
  min-height: 100vh;
}

.heading-intro {
  margin-top: 2em;
}

/* Accordion */
.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #212529;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

/* Forms */

.form-check-input:checked {
  background-color: #3a4e48;
  border-color: #3a4e48;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #3a4e48;
}

/* Modal */
.modal-backdrop {
  background-color: transparent;
}

/* Table */

.enhanced-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.table-loader {
  text-align: center !important;
}

.enhanced-table th,
.enhanced-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.enhanced-table thead th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Code format */

pre {
  background-color: #f8f9fa;
  padding: 15px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

/* Toast */

.toast-container {
  height: 200px;
  display: flex;
  justify-content: center;
}

.toast-b-right {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  width: auto;
}

.toast-background {
  background-color: white;
}

.toast-success {
  background-color: white;
}

.toast-error {
  background-color: #ff0000;
  color: #f3f2ef;
}

.input-error {
  border-color: #ff0000;
  border-radius: 5px;
}

/* Pagination */

.pagination .page-item.active .page-link {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
  color: #ffffff;
  z-index: 0;
}

.pagination .page-link {
  color: #ec6c5d;
}

.pagination .page-link:hover {
  z-index: 0;
}

/* Custom Button */
.custom-button-container {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  text-align: center;
  border: 2px;
  border-radius: 10px;
  margin: 12px;
  padding: 5px;
  height: 150px;
}

.buttons-container {
  max-width: 970px;
  text-align: center;
}

.custom-button-image {
  fill: #3a4e48;
  width: 50px;
  height: 60px;
}

.dashboard-body {
  align-items: center;
  text-align: center;
}

.image-row {
  justify-content: center;
  margin-bottom: 50px;
}

.custom-anchor {
  color: #212529 !important;
  text-decoration: none !important;
}

.custom-button-header {
  font-weight: 600;
  margin-top: 5px;
  color: #3a4e48;
}

.custom-button-description {
  font-size: small;
  margin: 5px;
}

.custom-buttons-container {
  align-items: center;
  text-align: center;
}

.section-header {
  font-size: large;
  font-weight: 600;
}

.custom-svg {
  filter: invert(28%) sepia(38%) saturate(213%) hue-rotate(111deg) brightness(92%) contrast(94%);
}

/* Tab styles */

.custom-tab .nav-link.active {
  background-color: #3a4e48 !important;
  color: #ffffff;
}

.custom-tab .nav-link {
  background-color: #ddd;
  color: #212529;
}

.custom-tab .nav-link:hover {
  background: #f2f2f2;
}

/* Log */
.search-btn-col {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-right: 45px;
}

/* Scrollable box */
.scrollable-checkbox-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dfe2e6;
  border-radius: 0.375rem;
  padding: 10px;
}

/* Category mapping */
.folder-item {
  display: flex;
  align-items: center;
}

.folder-item span {
  flex-grow: 1;
}

.folder-select {
  margin-left: auto;
  width: 39vw;
}

.folder-children {
  padding-left: 20px; /* Indent for child levels */
  margin-top: 10px; /* Space between parent and children */
}

.folder-children > li {
  margin-top: 10px; /* Space between children items */
}

.folder-children .folder-item {
  display: flex;
  align-items: center;
}
